import React from 'react';
import {withStyles} from '@material-ui/core';
import styles from 'components/commonStyles';
import PropTypes from 'prop-types';
import {graphql, StaticQuery, Link} from 'gatsby';
import Img from 'gatsby-image';
import get from 'lodash/get';

const Case6 = ({classes}) => (
  <StaticQuery
    query={graphql`
      query {
        image: allFile(
          filter: { relativePath: { eq: "other/api-chooser/6.png" } }
        ) {
          edges {
            node {
              id
              childImageSharp {
                id
                fluid(
                  srcSetBreakpoints: [200, 340, 520, 800, 890]
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const img = get(data, 'image.edges[0].node.childImageSharp.fluid', {});

      return (
        <div className={classes.case} id="legacy-pos">
          <div className={classes.head}>
            <h4 className={classes.h4Absolute}>Use Case #6. Legacy POS</h4>
          </div>
          <p className={classes.textHead} style={{marginBottom: 30}}>
            You have a PC-compatible POS with file-exchange and want to
            integrate it with other cases described here.
          </p>
          <Img fluid={img} style={{maxWidth: 574}} />
          <p style={{marginTop: 60}}>
            We have a special tool called “Sync adapter”, ready to be configured
            for your custom file format. Please
{" "}
            <Link to="/contact-us/">
              <span className={classes.textColor}>contact us</span>
            </Link>{" "}
            to know more.
          </p>
        </div>
      );
    }}
  />
);

Case6.propTypes = {
  classes: PropTypes.shape({
    case: PropTypes.string.isRequired,
    head: PropTypes.string.isRequired,
    h4Absolute: PropTypes.string.isRequired,
    textHead: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles, {withTheme: true})(Case6);
